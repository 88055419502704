import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["mobile"]

  connect() {
    useTransition(this, {
      element: this.mobileTarget
    })
  }
  
  close(event) {
    this.leave()
  }

  open() {
    this.enter();
  }
}

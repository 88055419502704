import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "panel"]

  change(event) {
    event.preventDefault()

    const tab = event.currentTarget.dataset.tab

    this.buttonTargets.forEach(element => {
      if (element.dataset.tab === tab) {
        element.classList.add('w--current')
      } else {
        element.classList.remove('w--current')
      }
    })

    this.panelTargets.forEach(element => {
      if (element.dataset.tab === tab) {
        element.classList.add('w--tab-active')
      } else {
        element.classList.remove('w--tab-active')
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["wrapper"]

  connect() {
    const cookie = getCookie(COOKIE_NAME)

    if (!cookie) {
      useTransition(this, {
        element: this.wrapperTarget
      })

      this.enter();
    }
  }

  close() {
    this.leave()
    setCookie(COOKIE_NAME, true, 30);
  }
}

const COOKIE_NAME = "_testly_cookie_settings"

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days *24 *60 *60 *1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

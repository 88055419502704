import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "pricingPanel"]

  change(event) {
    const kind = event.currentTarget.dataset.kind
    this._switchButtonClasses(kind)
    this._switchPricing(kind)
  }

  _switchButtonClasses(kind) {
    this.buttonTargets.forEach(element => {
      if (element.dataset.kind === kind) {
        INACTIVE_CLASS.forEach(klass => { element.classList.remove(klass) })
        ACTIVE_CLASS.forEach(klass => { element.classList.add(klass) })
      } else {
        ACTIVE_CLASS.forEach(klass => { element.classList.remove(klass) })
        INACTIVE_CLASS.forEach(klass => { element.classList.add(klass) })
      }
    })
  }

  _switchPricing(kind) {
    this.pricingPanelTargets.forEach(element => {
      if (element.dataset.kind === kind) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
      }
    })
  }
}

const ACTIVE_CLASS = ['bg-indigo-700', 'text-white']
const INACTIVE_CLASS = ['text-gray-500']

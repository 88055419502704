import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chevron", "answer"]
  static values = {
    show: Boolean
  }

  connect() {
    if (this.showValue === false) {
      this.answerTarget.classList.add('hidden')
    }
  }

  collapse(event) {
    event.preventDefault()

    this.answerTarget.classList.toggle('hidden')
    if (this.chevronTarget.classList.contains('rotate-0')) {
      this.chevronTarget.classList.remove('rotate-0')
      this.chevronTarget.classList.add('-rotate-180')
    } else {
      this.chevronTarget.classList.add('rotate-0')
      this.chevronTarget.classList.remove('-rotate-180')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.element.addEventListener('click', this.scroll)
  }

  disconnect () {
    this.element.removeEventListener('click', this.scroll)
  }

  scroll (event) {
    event.preventDefault()

    const id = this.element.hash.replace(/^#/, '')
    const target = document.getElementById(id)

    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`)
      window.location.href = this.element.href
      return
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }
}

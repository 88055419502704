// The input file for the landing page/website.
// We load here everything that is needed for the landing page/website.

import Rails from "@rails/ujs"

Rails.start()

import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import Dropdown from "./controllers/dropdown_controller"
import Tabs from "./controllers/public/tabs_controller"
import ScrollTo from "./controllers/public/scroll-to_controller"
import Nav from "./controllers/public/nav_controller"
import Cookies from "./controllers/public/cookies_controller"
import Pricing from "./controllers/public/pricing_controller"
import Accordion from "./controllers/public/accordion_controller"
import ServiceWorker from "./controllers/service_worker_controller.js"

application.register("dropdown", Dropdown)
application.register("tabs", Tabs)
application.register("scroll-to", ScrollTo)
application.register("nav", Nav)
application.register("cookies", Cookies)
application.register("pricing", Pricing)
application.register("accordion", Accordion)
application.register("service-worker", ServiceWorker)
